import type { RouteLocationNormalized } from "vue-router";
import type { CartItemDto } from "~/types/core/ecommerce/cart";
import type { SingleProduct } from "~/types/single-product";
import type { Packet } from "~/types/universe";
import type { Level } from "~/utils/constants";

export const useProductCatalogueStore = defineStore("productCatalogue", () => {
  const isFetching = ref(false);
  const products = ref<Array<Packet | SingleProduct>>([]);

  async function fetchProductCatalogue(route?: RouteLocationNormalized) {
    if (isFetching.value) {
      return;
    }

    isFetching.value = true;
    try {
      const options = (path: string) => ({
        path,
        language: "no-nb",
        version: useCrystallizeVersion(route),
      });

      const results = await Promise.all([
        // TODO: Remove this when everything has been moved to produktkatalog
        GqlGetCatalogue(options("/aunivers")),
        GqlGetCatalogue(options("/produktkatalog")),
      ]);

      products.value = results.flatMap((it) =>
        it.catalogue ? toProducts(it.catalogue) : [],
      ).filter((it) => it.isCanonical);
    } catch (e) {
      handleError(e, "Failed to fetch product catalogue", false);
    } finally {
      isFetching.value = false;
    }
  }

  function getFromSlug(slug: string, level?: Level) {
    const matches = products.value.filter(bySlug(slug));

    return (
      (level && matches.find((packet) => packet.levels?.includes(level))) ||
      matches[0]
    );
  }

  return {
    products,

    async ensureProductCatalogue(route?: RouteLocationNormalized) {
      if (!products.value.length) {
        await fetchProductCatalogue(route);
      }
    },

    getFromSlug,

    getVariant(isbnOrItem: string | CartItemDto) {
      const variants = products.value.map((it) => it.variants).flat();

      if (typeof isbnOrItem === "string") {
        return variants.find((it) => it.isbn === isbnOrItem);
      }

      let variant = variants.find((it) => it.isbn === isbnOrItem.productId);

      if (
        !variant &&
        isbnOrItem.subscriptionPlan &&
        isbnOrItem.subscriptionPeriod
      ) {
        variant = variants.find(
          (it) =>
            it.subscriptionsPlan?.identifier === isbnOrItem.subscriptionPlan &&
            it.subscriptionsPlan?.period?.id === isbnOrItem.subscriptionPeriod,
        );
      }

      if (!variant && isbnOrItem.productName) {
        variant = variants.find((it) => it.title === isbnOrItem.productName);
      }

      return variant;
    },
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useProductCatalogueStore, import.meta.hot),
  );
}
